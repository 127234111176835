import { StorageService } from './services/storage/storage.service';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { IonicModule } from '@ionic/angular';
import { ErrorHandlerInterceptor } from './services/error-handler/error-handler.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { SmartAudioService } from './services/smart-audio/smart-audio.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ConfigService } from './services/config/config.service';

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        // Ionic
        IonicModule.forRoot(),

        IonicStorageModule.forRoot(),
        
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,


        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({})
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        NativeAudio,
        SmartAudioService,
        StorageService,
        ConfigService
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
