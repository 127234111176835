<div class="h-auto w-auto min-h-full min-w-full flex flex-col justify-around items-center">

  <ion-card-header class="bg-primary w-full">
    <ion-card-title class="text-white text-center text-lg uppercase">Richiesta autorizzazione</ion-card-title>
  </ion-card-header>

  <ion-card-content class="flex flex-col items-center ">

    <p class="text-center text-lg my-2">Per continuare con l'operazione selezionata è necessario
      confermare
      l'autorizzazione da un supervisore.</p>

    <ion-select label="supervisor" class="w-auto min-w-max text-lg mt-2 mb-6 border rounded-lg border-gray-300"
      placeholder="Scegli un supervisore" (ionChange)="onChange($event)">
      <ion-select-option *ngFor="let supervisor of supervisors" [value]="supervisor.operatoreID">
        {{supervisor.name}}</ion-select-option>
    </ion-select>

    <div class="felx flex justify-center gap-2 min-w-max">
      <button mat-raised-button class="bg-gray-600 text-white" (click)="onClickCancel()">Annulla</button>
      <button mat-raised-button color="primary" [ngClass]="" [disabled]="!superSelected"
        (click)="onClickAuth()">Autorizza</button>
    </div>
  </ion-card-content>

</div>