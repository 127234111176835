import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { PopupService } from '../popup/popup.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _popupService: PopupService)
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone();

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch responses
                if ( error instanceof HttpErrorResponse && error.status === 500 )
                {
                    this._popupService.presentPopup('Errore', 'Server non disponibile, controllare la connettività.');
                } else {
                    this._popupService.presentPopup('Errore', 'Errore imprevisto durante una chiamata al server, riprovare.');
                }

                return throwError(error);
            })
        );
    }
}
