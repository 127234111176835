import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from "@ionic/angular";
import { AlertController } from '@ionic/angular';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

interface Supervisors {
  name: string;
  lastName?: string;
}

@Component({
  selector: 'supervisor-auth',
  templateUrl: './supervisor-auth.component.html',
  styleUrls: ['./supervisor-auth.component.scss']
})
export class SupervisorAuthComponent implements OnInit {
  user: User
  supervisors: User[] = []
  supervisorSelected: User;
  supervisorId: number;
  superSelected: boolean = false;

  constructor(private _modalController: ModalController, private _alertController: AlertController, private _userService: UserService, private navParams: NavParams) {
  }

  operation: string = this.navParams.data.operation 
  message: string = this.navParams.data.message 

  ngOnInit(): void {
    this.getSupervisors()
    this._userService.user$.subscribe((user) => {
      this.user = user
    })
  }

  onChange(event: any) {
    this.superSelected = true;
    this.supervisorId = event.target.value;
    this.supervisorSelected = this.supervisors.find((user) => user.operatoreID == this.supervisorId);
    console.log(this.supervisorSelected);
  }

  getSupervisors() {
    this._userService.getSupervisors().subscribe((supervisors) => {
      this.supervisors = supervisors;
      console.log(supervisors);
    })
  }

  async onClickCancel() {
    const alert = await this._alertController.create({
      header: "Autorizzazione negata",
      message: "Nessun supervisore selezionato"
    })
    this._modalController.dismiss(false);
    await alert.present()
    setTimeout(() => alert.dismiss(), 1250);
  }

  async onClickAuth() {
    if (this.superSelected) {
      const alert = await this._alertController.create({
        header: "Autorizzazione approvata",
        message: "Supervisore: " + this.supervisorSelected.name
      })
      this._userService.logSupervisor(this.operation, this.message, this.user.operatoreID, this.user.name, this.supervisorSelected.operatoreID, this.supervisorSelected.name).subscribe(() => {
        this._modalController.dismiss(this.superSelected)
      }, (error) => {
        console.log("error logSupervisor():");
        console.log(error);
        this._modalController.dismiss(false);
      })
      await alert.present()
      setTimeout(() => alert.dismiss(), 1250);
    }
  }

}
