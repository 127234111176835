import { StorageService } from './services/storage/storage.service';
import { SmartAudioService } from './services/smart-audio/smart-audio.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/core/user/user.service';
import { Component } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { Platform } from '@ionic/angular';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{

    constructor(
        private _authService: AuthService, 
        private _userService: UserService, 
        private _navigationService: NavigationService, 
        private platform: Platform, 
        private smartAudioService: SmartAudioService,
        private storageService: StorageService)
    {
        this.storageService.init();
        this.smartAudioService.preload('beep-ok', 'assets/audio/beep-ok.wav');
        this.smartAudioService.preload('beep-wrong', 'assets/audio/beep-wrong.wav');
        this._userService.load();
    }

    ngOnInit() {
    }
}
