/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'desktop',
        title: 'Desktop',
        type : 'basic',
        icon : 'heroicons_outline:arrow-circle-right',
        link : '/desktop',
        role : ''
    },
    {
      id   : 'tvcontrol',
      title: 'TV Control',
      type : 'basic',
      icon : 'heroicons_outline:arrow-circle-right',
      link : '/tvcontrol',
      role : ''
    },
    {
      id   : 'analytics',
      title: 'Statistiche',
      type : 'basic',
      icon : 'heroicons_outline:arrow-circle-right',
      link : '/analytics',
      role : ''
    },
    {
      id   : 'wms',
      title: 'Logistica',
      type : 'basic',
      icon : 'heroicons_outline:arrow-circle-right',
      link : '/wms',
      role : 'wms'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'desktop',
        title: 'Desktop',
        type : 'basic',
        icon : 'heroicons_outline:arrow-circle-right',
        link : '/desktop',
        role : ''
    },
    {
      id   : 'tvcontrol',
      title: 'TV Control',
      type : 'basic',
      icon : 'heroicons_outline:arrow-circle-right',
      link : '/tvcontrol',
      role : ''
    },
    {
      id   : 'analytics',
      title: 'Statistiche',
      type : 'basic',
      icon : 'heroicons_outline:arrow-circle-right',
      link : '/analytics',
      role : ''
    },
    {
      id   : 'wms',
      title: 'Logistica',
      type : 'basic',
      icon : 'heroicons_outline:arrow-circle-right',
      link : '/wms',
      role : 'wms'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id   : 'desktop',
    title: 'Desktop',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/desktop',
    role : ''
  },
  {
    id   : 'tvcontrol',
    title: 'TV Control',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/tvcontrol',
    role : ''
  },
  {
    id   : 'analytics',
    title: 'Statistiche',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/analytics',
    role : ''
  },
  {
    id   : 'wms',
    title: 'Logistica',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/wms',
    role : 'wms'
  }
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id   : 'desktop',
    title: 'Desktop',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/desktop',
    role : ''
  },
  {
    id   : 'tvcontrol',
    title: 'TV Control',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/tvcontrol',
    role : ''
  },
  {
    id   : 'analytics',
    title: 'Statistiche',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/analytics',
    role : ''
  },
  {
    id   : 'wms',
    title: 'Logistica',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/wms',
    role : 'wms'
  },
  {
    id   : 'returns',
    title: 'Resi',
    type : 'basic',
    icon : 'heroicons_outline:arrow-circle-right',
    link : '/returns',
    role : 'returns'
  },
  {
    id   : 'admin-user',
    title: 'Crea utente',
    type : 'basic',
    icon : 'heroicons_outline:user-circle',
    link : '/admin/user',
    role : 'admin'
  }
];
