import { ReturnsModule } from './modules/returns/returns.module';
import { WmsModule } from './modules/wms/wms.module';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { SupervisorAuthComponent } from './modules/auth/supervisor-auth/supervisor-auth.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    {path: '', pathMatch : 'full', redirectTo: 'desktop'},
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'desktop'},
    // Non-authenticated routes
    {path: 'sign-in', canActivate: [NoAuthGuard], canActivateChild: [NoAuthGuard], component: LayoutComponent, data: { layout: 'empty'}, loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
    {path: 'confirmation-required', canActivate: [NoAuthGuard], canActivateChild: [NoAuthGuard], component: LayoutComponent, data: { layout: 'empty'}, loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
    {path: 'forgot-password', canActivate: [NoAuthGuard], canActivateChild: [NoAuthGuard], component: LayoutComponent, data: { layout: 'empty'}, loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
    {path: 'reset-password', canActivate: [NoAuthGuard], canActivateChild: [NoAuthGuard], component: LayoutComponent, data: { layout: 'empty'}, loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
    {path: 'sign-up', canActivate: [NoAuthGuard], canActivateChild: [NoAuthGuard], component: LayoutComponent, data: { layout: 'empty'}, loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
    // Authenticated routes
    {path: 'desktop', canActivate: [AuthGuard], component: LayoutComponent, loadChildren: () => import('app/modules/admin/desktop/desktop.module').then(m => m.DesktopModule)},
    {path: 'admin/user', canActivate: [AuthGuard], component: LayoutComponent, loadChildren: () => import('app/modules/admin/user/user.module').then(m => m.UserModule)},
    {path: 'tvcontrol', canActivate: [AuthGuard], component: LayoutComponent, loadChildren: () => import('app/modules/tvcontrol/tvcontrol.module').then(m => m.TvcontrolModule)},
    {path: 'analytics', canActivate: [AuthGuard], component: LayoutComponent, loadChildren: () => import('app/modules/analytics/analytics.module').then(m => m.AnalyticsModule)},
    {path: 'wms', canActivate: [AuthGuard], component: LayoutComponent, loadChildren: () => import('app/modules/wms/wms.module').then(m => m.WmsModule)},
    {path: 'returns', canActivate: [AuthGuard], component: LayoutComponent, loadChildren: () => import('app/modules/returns/returns.module').then(m => m.ReturnsModule)},
    {path: 'sign-out', canActivate: [AuthGuard], component: LayoutComponent, data: { layout: 'empty'}, loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
    {path: 'unlock-session', canActivate: [AuthGuard], component: LayoutComponent, data: { layout: 'empty'}, loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)},
    {path: 'supervisor-auth', canActivate: [AuthGuard], component: SupervisorAuthComponent, data: { layout: 'empty'}, loadChildren: () => import('app/modules/auth/supervisor-auth/supervisor-auth.module').then(m => m.SupervisorAuthModule)}
    /*
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },
    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
          {path: 'desktop', loadChildren: () => import('app/modules/admin/desktop/desktop.module').then(m => m.DesktopModule)},
          {path: 'admin/user', loadChildren: () => import('app/modules/admin/user/user.module').then(m => m.UserModule)},
          {path: 'tvcontrol', loadChildren: () => import('app/modules/tvcontrol/tvcontrol.module').then(m => m.TvcontrolModule)},
          {path: 'analytics', loadChildren: () => import('app/modules/analytics/analytics.module').then(m => m.AnalyticsModule)},
          {path: 'wms', loadChildren: () => import('app/modules/wms/wms.module').then(m => m.WmsModule)},
          {path: 'returns', loadChildren: () => import('app/modules/returns/returns.module').then(m => m.ReturnsModule)},
          {path: 'utility', loadChildren: () => import('app/modules/wms/wms.module').then(m => m.WmsModule)}
        ]
    }*/
];
