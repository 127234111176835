import { material } from './../../mock-api/ui/icons/data';
import { UserService } from './../user/user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Navigation } from 'app/core/navigation/navigation.types';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { horizontalNavigation } from 'app/mock-api/common/navigation/data';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;
    private _currentUserRoles: string[] = [];
    /**
     * Constructor
     */
    constructor(private _userService: UserService)
    {
      this._userService.user$.subscribe((user) => {
        this.build(user?.roles);
      })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */

    build(userRoles: string[])
    {
      if (!userRoles)
        return;
      this._currentUserRoles = userRoles;
      const navigation = this._horizontalNavigation.filter(p => this._currentUserRoles.includes(p.role) || this._currentUserRoles.includes('all'));
      this._horizontalNavigation = navigation;
      this._navigation.next({
        horizontal: this._horizontalNavigation,
        compact: [],
        default: this._horizontalNavigation,
        futuristic: []
      });
    }

}
