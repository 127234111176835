import { AlertController, ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { SupervisorAuthComponent } from 'app/modules/auth/supervisor-auth/supervisor-auth.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

constructor(private _alertController: AlertController, private _modalController: ModalController) { }

  async presentPopup(title: string, message: string, callback?) {
    const alert = await this._alertController.create({
      cssClass: 'basic-alert',
      header: title,
      message: message,
      buttons: ['Ok']
    });

    if (callback) {
      alert.onDidDismiss().then((value) => {
          callback(value);
      });
    }

    await alert.present();
  }

  async presentAutorizationModal(operation: string, message: string, callback?) {
    const modal = await this._modalController.create({
      component: SupervisorAuthComponent,
      cssClass: 'my-modal-class',
      componentProps: { operation: operation, message: message }
    })
    if (callback) {
      modal.onDidDismiss().then((value) => {
        if (value.data) {
          console.log(value.data);

          callback(value)
        }
      });
    }
    modal.present();
  }

  async presentQuantityPopup(articleReference, countedQuantity, callback?) {
    if (!articleReference)
      return;
    const alert = await this._alertController.create({
      cssClass: 'basic-alert',
      header: 'Modifica quantità',
      message: 'Inserisci la quantità rilevata manualmente',
      inputs: [
        {
          name: 'quantity',
          type: 'number',
          min: 1,
          max: 2500,
          value: countedQuantity
        }
      ],
      buttons: ['Ok',  {text: 'Annulla', role: 'cancel'}]
    });

    if (callback) {
      alert.onDidDismiss().then((value) => {
        callback(value);
      });
    }

    await alert.present().then(() => {
      const input: any = document.querySelector('ion-alert input[type="number"]');
      //input?.focus();
      input?.select();
      return;
    });
  }

  async presentQuestionPopup(title: string, message: string, callback?, options?: string) {
    const alert = await this._alertController.create({
      cssClass: 'basic-alert',
      header: title,
      message: message,
      buttons: [
        {
          text: 'SI',
          role: 'confirm',
          cssClass: options == 'large_yes' ? 'alert-large-yes-button' : ''
        },
        {
          text: 'NO',
          role: 'cancel'
        }
      ]
    });

    if (callback) {
      alert.onDidDismiss().then((value) => {
          callback(value);
      });
    }

    await alert.present();
  }

}
