import { Warehouse } from '../../../services/wms/domain/warehouse.item';
/* eslint-disable */
export const user = {
    id    : 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
    name  : 'Giuseppe Simeone',
    email : 'giuseppe@saltifratelli.it',
    avatar: 'assets/images/avatars/brian-hughes.jpg',
    status: 'online',
    warehouse: {
      id: 1,
      code: '1',
      description: 'Parma'
    }
};
