import { UserService } from 'app/core/user/user.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    constructor(
        private _router: Router,
        private _userService: UserService
    )
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check('/');
    }

    private _check(redirectURL: string): Observable<boolean>
    {
        return this._userService.user$.pipe(
            catchError(() => {
                return of(false);
            }),
            switchMap((user) => {
                let authenticated = user !== undefined && user !== null;
                if (!authenticated)
                    this._router.navigateByUrl('/sign-in');
            return of(authenticated);
        }));
    }
}
