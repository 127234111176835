import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Parameter } from '../wms/domain/parameter';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private _httpClient: HttpClient) { }

  async getParameter(name: string): Promise<Parameter> {
    return this._httpClient.get<Parameter>(environment.server + '/api/config/parameters?name=' + name).toPromise();
  }


}
